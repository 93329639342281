<template>
	<div class="horizontal-navbar">
		<el-menu mode="horizontal" background-color="#202124" text-color="#fff" active-text-color="#15cf00">
			<el-menu-item index="1">
				Simon Kravec
				<img class="avatar" src="https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png" alt="">
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.horizontal-navbar::v-deep {
	ul {
		li {
			float: right;
			
			.avatar {
				height: 30px;
				width: 30px;
				object-fit: cover;
				border-radius: 50%;
			}
		}
	}
}
</style>