<template>
	<div class="component-graph">
		<el-row v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="min-height: 100vh">
<!--			<el-col class="graph-wrapper" :span="10" :offset="1">-->
<!--				<v-chart class="graph" :option="graphOptions" />-->
<!--			</el-col>-->
			
			<el-col v-for="graph in graphs" :key="graph.id" class="graph-wrapper" :span="10" :offset="1">
				<v-chart class="graph" :option="graph.options" />
			</el-col>
		</el-row>
	</div>
</template>

<script>
import axios from "axios"
import config from "@/config"

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import * as echarts from 'echarts';
// import { PieChart } from "echarts/charts";
import {
	TitleComponent,
	TooltipComponent,
	LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
	CanvasRenderer,
	// PieChart,
	TitleComponent,
	TooltipComponent,
	LegendComponent
]);

export default {
	components: {
		VChart
	},
	provide: {
		[THEME_KEY]: "dark"
	},
	data() {
		return {
			loading: true,
			
			graphs: [],
			
			graphOptions: {
				color: ['#FF8080', '#15CF00'],
				backgroundColor: '',
				title: {
					text: 'Budget',
					textStyle: {
						fontSize: 16,
					}
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['Budget', 'Logged time',],
					top: 30,
				},
				toolbox: {
					feature: {
						saveAsImage: {}
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'time',
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: 'Budget',
						type: 'line',
						smooth: true,
						lineStyle: {
							width: 0
						},
						showSymbol: false,
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{
									offset: 0,
									color: 'rgb(255,128,128)'
								},
								{
									offset: 1,
									color: 'rgba(1, 191, 236, 0)'
								}
							])
						},
						emphasis: {
							focus: 'series'
						},
						data: []
					},
					{
						name: 'Logged time',
						type: 'line',
						smooth: true,
						lineStyle: {
							width: 0
						},
						showSymbol: false,
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{
									offset: 0,
									color: 'rgba(21, 207, 0)'
								},
								{
									offset: 1,
									color: 'rgba(77, 119, 255, 0)'
								}
							])
						},
						emphasis: {
							focus: 'series'
						},
						data: []
					},
					
				]
			},
			
		}
	},
	methods: {
		
		getProjects() {
			this.loading = true;
			
			axios.get('https://api.npoint.io/78863126ec6500e9ba4b')
			.then((response) => {
				console.warn(response);
				let projects = response.data.projects;
				
				projects.map(async (project, index) => {
					let data = await this.getLoggedTime(project);
					this.graphs.push(data);
					
					if (index + 1 === projects.length) {
						this.loading = false;
					}
				})
			})
		},
		
		async getLoggedTime(project) {
			return new Promise((resolve) => {
				
				let userId = project.userId;
				let projectId = project.projectId;
				let taskList = (project.taskListId !== null ? '&taskListId=' + project.taskListId : '');
				let estimatedTime = project.estimatedTime;
				
				axios.get(`https://wame.teamwork.com/projects/api/v3/projects/${projectId}/time.json?assignedToUserIds=${userId}${taskList}`, config.TWHeaders)
					.then((res) => {
						console.warn(res);
						let timelogs = res.data.timelogs;
						
						let tempTotal = 0;
						let tempData = [];
						
						let startDate = 0;
						let finalDate = 0;
						
						timelogs.forEach((log, index) => {
							tempTotal += (log.minutes / 60);
							
							let created = new Date(log.timeLogged)
							// let created = [createdAt.getFullYear(), createdAt.getMonth(), createdAt.getDate()].join('/')
							
							tempData.push({
								name: 'test',
								value: [
									created,
									tempTotal
								]
							});
							
							if (index === 0) {
								startDate = created;
							}
							
							if (index + 1 === timelogs.length) {
								// console.warn('last')
								finalDate = created;
								
								
								let totalTimeData = [];
								totalTimeData.push([
									startDate,
									estimatedTime
								], [
									finalDate,
									estimatedTime
								])
								
								let graphOptions = JSON.parse(JSON.stringify(this.graphOptions));
								// console.warn(graphOptions);
								graphOptions.title.text = project.projectName;
								
								graphOptions.series[0].data = totalTimeData;
								graphOptions.series[1].data = tempData;
								
								resolve({
									id: project.id,
									options: graphOptions
								});
								
								// this.graphs.push()
								
								// this.graphOptions.series[0].data = totalTimeData;
								// this.graphOptions.series[1].data = tempData;
								
							}
						})
						
					})
			
			})
		}
	},
	mounted() {
		// this.getLoggedTime(331418, 560511, 2547763, 120);
		console.warn('mounted is changed');
		this.getProjects();
	}
};
</script>

<style lang="scss" scoped>
.component-graph {
	
	.graph-wrapper, .settings-wrapper {
		background: #2e3134;
		padding: 25px;
		margin-top: 30px;
		border-radius: 20px;
	}
	
	.graph-wrapper {
		.graph {
			height: 400px;
		}
	}
}
</style>