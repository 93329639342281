<template>
	<div class="page page-home">
		<Hnavbar />
		
		<Graph />
	</div>
</template>

<script>
import Hnavbar from "@/components/layout/Hnavbar"

import Graph from "@/components/Graph";

export default {
	components: {
		Hnavbar,
		
		Graph,
	},
	data() {
		return {
		
		}
	},
}
</script>